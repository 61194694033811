<template>
  <div class="container">
    <!-- 表头 -->
    <div class="topup-hread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        <el-breadcrumb-item>{{ id.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="topup-title">活动详情</div>
    <div style="width: 90%">
      <el-form :model="id" ref="ruleForm" label-width="100px" style="margin-bottom: 80px" :inline="true">
        <el-form-item label="活动类型">
          <el-input v-model="id.name"></el-input>
        </el-form-item>
        <el-form-item label="活动标题">
          <el-input v-model="id.descr"></el-input>
        </el-form-item>
        <el-form-item label="活动简介">
          <el-input v-model="id.activity_description"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-input v-model="id.start_time"></el-input>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-input v-model="id.end_time"></el-input>
        </el-form-item>

        <el-form-item label="科目数">
          <el-input v-model="id.choose_num"></el-input>
        </el-form-item>
        <el-form-item label="科目价格">
          <el-input v-model="id.pay_amount"></el-input>
        </el-form-item>
        <el-form-item label="增加一科金额">
          <el-input v-model="id.add_amount"></el-input>
        </el-form-item>
        <el-form-item label="定金">
          <el-input v-model="id.pay_deposit"></el-input>
        </el-form-item>
        <el-form-item label="单独购买价格">
          <el-input v-model="id.separate_amount"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <!-- 查看参加活动机构 -->
    <div>
      <div class="topup-title">已参加该活动机构</div>
      <el-table :data="tableListData" height="250" border style="width: 100%">
        <el-table-column type="index" width="100" label="序号">
        </el-table-column>
        <el-table-column prop="id" label="id" align="center" width="100">
        </el-table-column>
        <el-table-column prop="mechanism_name" align="center" width="150" label="机构名">
        </el-table-column>
        <el-table-column prop="mechanism_addr" align="center" label="地址">
        </el-table-column>
        <el-table-column prop="contacts" align="center" label="联系人">
        </el-table-column>
        <el-table-column prop="contact_telephone" align="center" label="联系方式">
        </el-table-column>
      </el-table>
    </div>
    <div class="topup-title">活动海报</div>
    <div style="position: relative">
      <el-button @click="TalentedCoursesChange" style="position: absolute; right: 5%; top: -65px">修改</el-button>

      <el-form :model="HaibaoData" ref="ruleForm" label-width="140px" class="demo-ruleForm">
        <div style="display: flex;flex-wrap: wrap;">
          <div style="width: 45%">
            <el-form-item label="礼物图片">
              <el-upload class="avatar-uploader" :action="domain" :show-file-list="false" :on-success="Uploadimg"
                :data="QiNiYunL" :before-upload="gettoken" multiple>
                <div style="display: flex">
                  <div v-for="(item, index) in HaibaoData.gift_pic" :key="index" style="position: relative">
                    <i class="el-icon-close" style="
                        position: absolute;
                        right: 3px;
                        top: 3px;
                        font-size: 20px;
                      " @click="deleteimg(index)"></i>
                    <img :src="item" class="avatar" style="width: 100px; height: 100px" @click="SetupImg(index)" />
                  </div>
                  <div>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="分享浮标">
              <avatar-uploader @getImgUrl="Upload_share_buoy_pic" :clear="if_clear" :url='HaibaoData.share_buoy_pic'>
              </avatar-uploader>
            </el-form-item>
            <el-form-item label="订金浮标">
              <avatar-uploader @getImgUrl="Upload_deposit_float_pic" :clear="if_clear"
                :url='HaibaoData.deposit_float_pic'></avatar-uploader>
            </el-form-item>
            <el-form-item label="弹窗浮标">
              <avatar-uploader @getImgUrl="Upload_deposit_alert_pic" :clear="if_clear"
                :url='HaibaoData.alert_pic'></avatar-uploader>
            </el-form-item>
            <!-- <el-form-item label="背景图片">
              <el-upload
                class="avatar-uploader1"
                :action="domain"
                :show-file-list="false"
                :on-success="Upload_background_image"
                :data="QiNiYunL"
                :before-upload="gettoken"
                multiple
              >
                <img
                  v-if="HaibaoData.background_image"
                  :src="HaibaoData.background_image"
                  class="avatars"
                  style="height: 115px"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon">
                  <img src alt />
                </i>
              </el-upload>
            </el-form-item> -->
          </div>
          <div>
            <el-form-item label="课程表">
              <avatar-uploader @getImgUrl="Upload_schedule_pic" :clear="if_clear" :url='HaibaoData.schedule_pic'>
              </avatar-uploader>
            </el-form-item>
            <el-form-item label="红包图片">
              <avatar-uploader @getImgUrl="Upload_alert_pic" :clear="if_clear" :url='HaibaoData.alert_pic'>
              </avatar-uploader>
            </el-form-item>
            <el-form-item label="分享页面背景图片">
              <avatar-uploader @getImgUrl="Upload_mechanism_address" :clear="if_clear"
                :url='HaibaoData.mechanism_address'></avatar-uploader>
            </el-form-item>
            <!-- <el-form-item label="天赋卡卡片">
              <avatar-uploader @getImgUrl="Upload_schedule_pic" :clear="if_clear" :url='HaibaoData.schedule_pic'>
              </avatar-uploader>
            </el-form-item> -->
          </div>
        </div>
        <el-form-item label="环境图" style="margin-bottom: 30px">
          <div style="display:flex;flex-wrap: wrap;">
            <avatar-uploader @getImgUrl="Uploadenpic1" :clear="if_clear" :url='en_pic[0]'></avatar-uploader>
            <avatar-uploader @getImgUrl="Uploadenpic2" :clear="if_clear" :url='en_pic[1]'></avatar-uploader>
            <avatar-uploader @getImgUrl="Uploadenpic3" :clear="if_clear" :url='en_pic[2]'></avatar-uploader>
            <avatar-uploader @getImgUrl="Uploadenpic4" :clear="if_clear" :url='en_pic[3]'></avatar-uploader>
          </div>
          </el-form-item>
        <el-form-item label="背景色号">
          <colorPicker v-model="color" />
        </el-form-item>
        <el-form-item label="声明警示">
          <el-input :rows="4" type="textarea" v-model="HaibaoData.statement"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventDetails",
  data() {
    return {
      id: {},
      color: "",
      tableListData: [],
      HaibaoData: { gift_pic: [] },
      // 图片上传
      activeImg: -1,
      dialogImageUrl: "",
      domain: "http://up-z0.qiniup.com",
      upload_qiniu_addr: "http://img.curiousmore.com/",
      QiNiYunL: {
        key: "",
        token: "",
      },
      if_clear: true,
      en_pic:[]
    };
  },
  mounted() {
    this.getActivityInfo()
    this.gettoken();
    this.getOldList();
    this.getHaibao(this.$route.query.id);
  },
  methods: {
    getActivityInfo() {
      let url =
        "/user/businessActivityType/queryManagerListPage?id=" +this.$route.query.id;
      this.$axios.get(url).then((res) => {
        // console.log(res);
        this.id = res.data.data.rows[0]
      });
    },
    //图片操作
    Uploadimg(file) {
      console.log(file);
      if (this.activeImg == -1) {
        this.dialogImageUrl = this.upload_qiniu_addr + file.key;
        this.HaibaoData.gift_pic.push(this.dialogImageUrl);
      } else {
        this.dialogImageUrl = this.upload_qiniu_addr + file.key;
        this.HaibaoData.gift_pic[this.activeImg] = this.dialogImageUrl;
        this.activeImg = -1;
      }
    },
    SetupImg(index) {
      this.activeImg = index;
    },
    deleteimg(index) {
      this.HaibaoData.gift_pic.splice(index, 1);
    },
    getOldList() {
      let params = {
        id: this.this.$route.query.id,
        type: 'teach_payal',
        pageSize: 10,
        currentPage: 1
      }
      this.$axios({
        url: "/user/mastermechanism/queryMechanismActivityList",
        params,
        method: 'get'
      }).then((res) => {
        this.tableListData = res.data.data;
      });
    },
    //已参加活动机构
    // getOldList() {
    //   this.$axios
    //     .get(

    //       "/user/mastermechanism/queryMechanismActivityList?id=" +
    //       this.this.$route.query.id +
    //       "&type=teach_payal"
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       this.tableListData = res.data.data;
    //       if (this.tableListData[0] != null) {
    //         this.listCourse = this.tableListData[0].map.courseList;
    //       } else {
    //         this.listCourse = [];
    //       }
    //     });
    // },

    getHaibao(id) {
      // let url =
      //   "/user/businessMechanismActivityType/queryActivityDetail?id=" +id;
      // this.$axios.get(url).then((res) => {
      //   console.log(res);
      //   if(res.data.data != null){
      //     this.HaibaoData = res.data.data
      //   if(!this.HaibaoData.gift_pic){
      //        this.HaibaoData.gift_pic = []
      //   }
      //   }
      let url = "user/businessMechanismActivityType/queryManagerListPage"
      this.$axios.get(url).then((res) => {
        console.log(res);
        if (res.data.data != null) {
          let haibaoList = res.data.data.rows
          for(let i=0;i<haibaoList.length;i++){
            if(haibaoList[i].activity_id == id){
              this.HaibaoData = haibaoList[i]
              if (!this.HaibaoData.gift_pic) {
                this.HaibaoData.gift_pic = []
              }else{
                 this.HaibaoData.gift_pic = this.HaibaoData.gift_pic.split(',')
              }
              if (this.HaibaoData.environment_pic!="") {
                this.en_pic = this.HaibaoData.environment_pic.split(',')
              }
              return
            }
          }
          
        }
      });
    },
    TalentedCoursesChange() {
      //更新海报
      let url =
        "/user/businessMechanismActivityType/update";
      let data = {
        ...this.HaibaoData,
        environment_pic:this.en_pic.join(','),
        students_pic: this.color,
        activity_id: this.this.$route.query.id,
        status:2,
        // id: 3,
      };
      data.gift_pic ? (data.gift_pic = data.gift_pic.toString()) : "";
      this.$axios.post(url, data).then((res) => {
        this.$message(res.data.message);
      });
      this.dialogFormVisible2 = false;
    },
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp,
        type: "pic",
      };
      _this
        .$axios({
          method: "post",
          url: "http://101.37.66.151:8768/eg-api/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Upload_share_buoy_pic(file) {
      this.HaibaoData.share_buoy_pic = file;
    },
    Upload_deposit_float_pic(file) {
      this.HaibaoData.deposit_float_pic = file;
    },
    Upload_schedule_pic(file) {
      this.HaibaoData.schedule_pic = file;
    },
    Upload_alert_pic(file) {
      this.HaibaoData.alert_pic = file;
    },
    Upload_mechanism_address(file) {
      this.HaibaoData.mechanism_address = file;
    },
    Upload_environment_pic(file) {
      this.HaibaoData.environment_pic = file;
    },
    Upload_background_image(file) {
      this.HaibaoData.background_image = file;
    },
    Upload_deposit_alert_pic(file) {
      this.HaibaoData.alert_pic = file;
    },
    Uploadenpic1(file) {
      this.en_pic[0] = file;
    },
    Uploadenpic2(file) {
      this.en_pic[1] = file;
    },
    Uploadenpic3(file) {
      this.en_pic[2] = file;
    },
    Uploadenpic4(file) {
      this.en_pic[3] = file;
    }
  },
};
</script>

<style scoped>
.topup-hread {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.topup-hread>>>.el-breadcrumb__inner {
  font-weight: bold;
  font-size: 20px;
}

.topup-title {
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-top: 40px;
}

.item {
  margin-bottom: 18px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.el-upload-list__item,
.el-upload-list--picture-card,
.el-upload-list__item-actions,
.el-upload--picture-card,
.avatar-uploader-icon {
  font-size: 14px !important;
  color: #8c939d;
  width: 5.6rem !important;
  height: 5.6rem !important;
  line-height: 5.6rem !important;
  text-align: center;
}

.avatar {
  width: 5.6rem !important;
  height: 5.6rem !important;
  display: block;
  /* margin-right:0px; */
}
</style>